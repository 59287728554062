import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { CloseIcon, ImageIcon } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
import { BtwText } from './BtwText';
import { Image } from './Image';
import { Loader } from './Loader';
import { Stack } from './Stack';
import { cn } from './utils';
const AddImageCtaBase = forwardRef(function AddImageCtaBase(props, ref) {
    const { className, disabled, draggedOver, isLoading, onClick, subheading = (React.createElement(React.Fragment, null,
        "Add/drop an image (optional) ",
        React.createElement("br", null),
        "PNG, JPG, GIF or WebP up to 6MB")) } = props, rest = __rest(props, ["className", "disabled", "draggedOver", "isLoading", "onClick", "subheading"]);
    return (React.createElement(Stack, Object.assign({ className: cn(
        // Base styles
        'px-4', 'py-6', 'rounded', 'border', 'border-dashed', 'select-none', 
        // Transition
        'ease-smooth', 'duration-300', 'cursor-pointer', 'transition-combined', 'will-change-transform', {
            'opacity-50': disabled,
            'cursor-not-allowed': disabled,
            // Hover and active states
            'hover:bg-gray-50': !disabled,
            'hover:text-gray-900': !disabled,
            'hover:border-gray-600/40': !disabled,
            'active:bg-gray-100': !disabled,
            'active:translate-y-px': !disabled,
            'border-blue-600/20': draggedOver,
            'border-gray-600/20': !draggedOver,
        }, className), align: "center", gap: "2", justify: "center", onClick: disabled ? undefined : onClick, ref: ref, width: "full" }, rest), isLoading ? (React.createElement(Loader, null)) : (React.createElement(React.Fragment, null,
        React.createElement(ImageIcon, null),
        React.createElement(BtwText, { align: "center", leading: "relaxed", size: "xs", variant: "current", weight: "medium" }, subheading)))));
});
function ImageBase(props) {
    const { className, onRemove, onReplace } = props, rest = __rest(props, ["className", "onRemove", "onReplace"]);
    return (React.createElement("div", { className: cn('relative', 'w-full', 'rounded', 'group/image'), "data-testid": "card-image-container" },
        React.createElement(Image, Object.assign({ className: cn('rounded', 'object-cover', 'w-full', className) }, rest)),
        (onRemove || onReplace) && (React.createElement("div", { className: cn('rounded', 'inset-0', 'absolute', 'bg-white/80', 'opacity-0', 'group-hover/image:opacity-100', 'ease-smooth', 'duration-300', 'transition-combined') },
            React.createElement(Stack, { align: "center", direction: "row", gap: "2.5", height: "full", justify: "center", width: "full" },
                onReplace && (React.createElement(BtwButton, { size: "sm", variant: "secondary", onClick: onReplace, "data-testid": "replace-card-image" }, "Replace")),
                onRemove && (React.createElement(BtwButton, { onClick: onRemove, size: "sm", standaloneIcon: React.createElement(CloseIcon, null), variant: "secondary", "data-testid": "remove-card-image" })))))));
}
export const AddImageCta = Object.assign(AddImageCtaBase, {
    Image: ImageBase,
});
